// типа, это какое-то легаси

import type { ValueOf } from '~/interfaces';

export const formatNumber = (number: number | string) =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export const formatAmount = (number: number | string, placeholder = '-') => {
  if (!number) return placeholder;
  return `${formatNumber(number)}₽`;
};

export const transferValues = (from: any, to: any, excludeKeys: string[] = []) => {
  // Перенести все значения, включая его отсутствие
  const setPrimitive = (key: string, value: any) => {
    to[key] = value;
  };
  const setArray = (key: string, value: any) => {
    to[key] = [...value];
  };
  const setObject = (key: string, value: any) => {
    to[key] = { ...value };
  };
  Object.entries(from).forEach(([key, value]) => {
    if (key in to && !excludeKeys.includes(key)) {
      const isArray = value instanceof Array;
      if (isArray) return setArray(key, value);
      const isObject = typeof value === 'object';
      if (isObject) return setObject(key, value);
      setPrimitive(key, value);
    }
  });
};

export const getMapFromArray = <T extends object>(
  arrayToMap: T[],
  unrequiredMapKey = 'id' as keyof T,
) => {
  const reduceToEntries = arrayToMap.reduce((acc: [ValueOf<T>, T][], curr: T) => {
    let mapKey = unrequiredMapKey;
    const newEntity = typeof curr[unrequiredMapKey] === 'undefined';
    if (newEntity) mapKey = 'keyId' as keyof T;
    return [...acc, [curr[mapKey], curr]] as [ValueOf<T>, T][];
  }, []) as [ValueOf<T>, T][];
  // @ts-ignore
  return new Map([...reduceToEntries]);
};

export const sliceString = (toSlice: string, lim = 30) => {
  if (toSlice.length > lim) {
    return `${toSlice.slice(0, lim)}...`;
  }
  return toSlice;
};

export const getFormattedParameter = (count: number | string) => {
  const sanitizedCount = count.toString().trim();
  const parsedCount = Number(sanitizedCount);
  if (isNaN(parsedCount)) return '-';
  const countLength = sanitizedCount.length;
  if (countLength > 6) {
    return `${formatNumber((parsedCount / 1e6).toFixed(1))}М`;
  }
  if (countLength < 4) {
    return formatNumber(parsedCount);
  }
  return `${formatNumber((parsedCount / 1e3).toFixed(1))}К`;
};

export const sliceDate = (dateString: string) => dateString.slice(0, 10);

export const formatDate = (dateString: string) => {
  if (dateString.indexOf('-') === -1 || dateString.length < 10) {
    console.log('Неожиданный аргумент');
    return '';
  }

  try {
    return sliceDate(dateString).split('-').toReversed().join('.');
  } catch {
    return '';
  }
};

export const validateUrl = (url: string) => {
  try {
    (() => new URL(url))();
    return true;
  } catch {
    return 'Укажите корректную ссылку формата https:// ....';
  }
};

export const calcSmallWidth = () => window.innerWidth <= 1000;

export const setToLocalStorage = <T>(name: string, value: T): void => {
  if (typeof localStorage === 'undefined') return;
  localStorage.setItem(name, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(name: string): T | null => {
  if (typeof localStorage === 'undefined') return null;
  const item = localStorage.getItem(name);
  if (item !== null && item !== 'undefined') {
    try {
      return JSON.parse(item);
    } catch (error) {
      console.log(error);
    }
  }
  return null;
};

export const removeFromLocalStorage = (name: string): boolean => {
  const haveItem = getFromLocalStorage(name) !== null;
  if (haveItem) {
    localStorage.removeItem(name);
    return true;
  }
  return false;
};

export const fmtNum = (num: number): string => {
  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1)}м`;
  } if (num >= 1_000) {
    return `${(num / 1_000).toFixed(1)}к`;
  }
  return num.toString();
};
